.our_awesome_team_left_detail span {
  color: #fc7523;
}
.our_awesome_team_left_detail {
  max-width: 516px;
  width: 100%;
}
.our_awesome_team_title {
  padding-top: 22px;
}
.our_awesome_content-1 {
  padding-top: 22px;
}
.our_awesome_content_2 {
  padding-top: 30px;
}
.our_awesome_btn {
  padding-top: 42px;
}
.our_awesome_btn button.btn.btn-primary {
  background: #1f1e23;
  box-shadow: 0px 10px 36px rgb(0 0 0 / 20%), 0px 0px 0px 1px rgb(0 0 0 / 6%);
  padding: 19px 30px 20px 30px;
}
.our_awesome_btn button.btn.btn-primary:hover {
  background: transparent;
  border: 1px solid #1f1e23;
  color: #1f1e23;
}
.our_awesome_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.our_awesome_right_img {
  position: relative;
}
.our_awosome_img_box p {
  margin-top: 15px;
}
.our_awosome_img_box h2 {
  color: #fc7523;
}
.our_awosome_img_box {
  position: absolute;
  max-width: 277px;
  left: 139px;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  text-align: center;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 24px;
}
.our_awesome_wrapper {
  padding-bottom: 100px;
  padding-top: 100px;
}

/* Team PGE */
.our_chefe_wrapper {
  padding-top: 95px;
  padding-bottom: 100px;
}
.our_chefe_wrapper .hero_small_detail.we_service_small_title.m-auto {
  max-width: 226px;
  width: 100%;
}
.our_chef_box {
  max-width: 380px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  background: #ffffff;
  border: 1px solid #d6d6d6;
}
.our_chef_img img {
  max-width: 378px;
  width: 100%;
  height: 369px;
  object-fit: cover;
}
.our_chef_title {
  padding-top: 27px;
  padding-bottom: 26px;
}
.our_chef_title h4 {
  margin: 0;
  font-family: Playfair Display;
  font-weight: 700;
}
.our_chef_title p {
  margin-top: 12px;
}
.or_chef_box_bottom {
  text-align: center;
  background-color: #fff;
}
.our_chef_social_icon {
  background-color: #fff;
  max-width: 380px;
  width: 100%;
  height: 83px;
  border-top: 1px solid #d6d6d6;
  transition: all 0.3s ease;
}
.our_chef_social_icon ul {
  justify-content: center;
  padding: 30px 0;
}
.our_chef_box:hover ul li svg {
  color: #fff;
}
.our_chef_box:hover .or_chef_box_bottom {
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}
.our_chef_box:hover .our_chef_social_icon {
  background: #fc7523;
}
.our_chef_social_icon ul li svg {
  color: #1f1e23;
  margin-right: 25px;
}
.our_chefe_wrapper .col-lg-4 {
  margin-bottom: 30px;
}
.brand_logo_wrapper.our_chef {
  padding-top: 55px;
}

@media (max-width: 1235px) {
  .our_chef_box {
    max-width: 350px;
    margin: 0 auto;
  }
}

@media (max-width: 1120px) {
  .our_chef_box {
    max-width: 298px;
  }
}

@media (max-width: 1140px) {
  .our_awesome_team_left_detail {
    max-width: 460px;
    width: 100%;
  }
}
@media (max-width: 991px) {
  .our_awesome_wrapper .row {
    flex-direction: column-reverse;
  }
  .our_awesome_team_left_detail {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
  }
  .our_awesome_right_img {
    text-align: center;
  }
  .our_awosome_img_box {
    max-width: 277px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    margin-top: -130px;
    z-index: 3;
    position: relative;
    left: 0;
  }
  .hero_small_detail.we_service_small_title {
    margin: 0 auto;
  }
  .our_awesome_team_title {
    padding-top: 15px;
  }
  .our_awesome_content-1 {
    padding-top: 15px;
  }
  .our_awesome_content_2 {
    padding-top: 20px;
  }
  .our_awesome_btn {
    padding-top: 20px;
  }
  .our_awesome_wrapper {
    padding-bottom: 50px;
  }
  .our_awesome_team_title {
    text-align: center;
  }
  .our_chefe_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .our_awosome_img_box {
    max-width: 201px;
  }
}

@media (max-width: 600px) {
  .our_awesome_img img {
    height: 100%;
    object-fit: contain;
  }
  .our_awosome_img_box {
    max-width: 160px;
    margin-top: -60px;
  }
  .our_awesome_btn button.btn.btn-primary {
    padding: 15px;
  }
}
