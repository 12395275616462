.faq_wrapper {
  padding-top: 100px;
  padding-bottom: 85px;
}
.faq_left_img img {
  width: 526px;
  height: 752px;
  object-fit: cover;
}
.faq_right_detail .hero_small_detail.we_service_small_title {
  max-width: 235px;
  width: 100%;
}
.faq_content {
  padding-top: 25px;
}
.accordion {
  padding-top: 37px;
}
.accordion-button:not(.collapsed) {
  background: #fc7523 !important;
  box-shadow: 0px 2px 7px rgb(100 100 111 / 5%) !important;
  color: #ffffff !important;
  padding: 15px 24px 15px 19px;
}
.accordion-button {
  color: #1f1e23 !important;
  background: #fdf0e6 !important;
  border: 0 !important;
  box-shadow: 0px 2px 7px rgb(100 100 111 / 5%) !important;
}
.accordion-item {
  color: #1f1e23;
  background-color: transparent;
  border: transparent;
  margin-bottom: 30px;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 30px;
}
.accordion-body {
  background: #ffffff;
  border: 1px solid #ffece1;
  box-shadow: 0px 2px 7px rgb(100 100 111 / 5%);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #fc7523 !important;
  box-shadow: 0px 2px 7px rgb(100 100 111 / 5%) !important;
}
.accordion-button::after {
  background: url("../../images/faq_down_arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 13px;
  height: 7px;
}
.accordion-button:not(.collapsed)::after {
  background: url("../../images/faq_white_down_arrow.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* faq page */
.our_faq_wrapper {
  padding-top: 95px;
  padding-bottom: 134px;
}
.our_faq_wrapper .hero_small_detail {
  max-width: 233px;
  width: 100%;
}
.our_faq_wrapper .our_awesome_team_title {
  padding: 0;
}
.our_faq_wrapper .client_say_section_wrapper {
  padding-bottom: 61px;
}
.our_faq_wrapper .row {
  margin: 0 -15px !important;
}
.our_faq_right {
  margin: 0 15px;
}
.our_faq_left {
  margin: 0 15px;
}
.our_faq_right {
  margin: 0 15px;
}

@media (max-width: 1120px) {
  .faq_left_img {
    max-width: 450px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .faq_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .faq_left_img {
    margin: 0 auto;
  }
  .faq_right_detail {
    padding-top: 25px;
  }
  .faq_content {
    padding-top: 10px;
    text-align: center;
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
  }
  .accordion {
    padding-top: 20px;
  }
  .accordion-item:first-of-type {
    margin-bottom: 25px;
  }
  .our_faq_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .accordion-button {
    padding: 19px;
  }
}

@media (max-width: 767px) {
  .faq_wrapper {
    padding-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .faq_left_img img {
    height: 100%;
    object-fit: contain;
  }
  .accordion-item h5 {
    font-size: 16px;
    line-height: 1.2;
  }
}
