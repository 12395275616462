.troo_da_how_can_help_u_wrapper
  .hero_small_detail.we_service_small_title.m-auto {
  max-width: 208px;
  width: 100%;
}
.troo_da_how_can_help_u_wrapper {
  background: #fdf0e6 url("../../images/client_wrapper_bg_wave.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 84px;
  padding-bottom: 100px;
}

.troo_da_how_can_help_u_wrapper a {
  text-decoration: none;
}
.troo_da_blog_box {
  max-width: 380px;
  background: #ffffff;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.4s ease;
  cursor: pointer;
  padding: 61px 26px;
  text-align: center;
}
.detail_outer {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 20%);
  padding: 5px 17px;
  transition: all 0.4s ease;
  display: inline-flex;
}
.troo_da_blog_box_title {
  padding-top: 24px;
}
.troo_da_blog_box_content {
  padding-top: 20px;
}
.troo_da_blog_box_title h4 {
  margin: 0;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #1f1e23;
}
.troo_da_blog_box:hover::after {
  content: "";
  background: #1f1e23;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.troo_da_blog_box:hover {
  background: url("../../images/blog_bg_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 380px;
  height: 100%;
  position: relative;
}
.troo_da_blog_box > .troo_da_blog_inner_box {
  position: relative;
  z-index: 3;
}
.troo_da_blog_box:hover .troo_da_blog_box_title h4 {
  color: #fc7523;
}
.troo_da_blog_box:hover .detail_outer {
  background: rgba(252, 117, 35, 0.5);
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}
.troo_da_blog_box:hover .troo_da_blog_box_content p {
  color: #fff;
}
.read_all_article_btn {
  padding-top: 50px;
}
.read_all_article_btn button.btn.btn-primary {
  padding: 19px 22px 20px 22px;
}

/* blog page */
.troo_da_how_can_help_u_wrapper.our_blogs_wrapper {
  background: transparent;
}
.troo_da_how_can_help_u_wrapper.our_blogs_wrapper .troo_da_blog_box {
  border: 1px solid #ececec;
  margin: 15px;
}
.troo_da_how_can_help_u_wrapper.our_blogs_wrapper .row {
  margin: 0 -15px !important;
}
.read_all_article_btn.text-center.our_blogs_btn button.btn.btn-primary {
  padding: 19px 28px 20px 29px;
}

@media (max-width: 1235px) {
  .troo_da_blog_box {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }
  .troo_da_blog_box:hover {
    width: 350px;
  }
}

@media (max-width: 1140px) {
  .troo_da_blog_box {
    max-width: 300px;

  }
  .troo_da_blog_box:hover {
    max-width: 300px;
    height: 370px;
  }
}

@media (max-width: 991px) {
  .troo_da_how_can_help_u_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .troo_da_how_can_help_u_wrapper .col-lg-4 {
    margin-bottom: 15px;
  }
  .troo_da_blog_box:hover {
    max-width: 380px;
    width: 100%;
    height: 340px;
  }
  .read_all_article_btn {
    padding-top: 25px;
  }
  section.troo_da_how_can_help_u_wrapper.our_blogs_wrapper .row {
    margin: 0 0 !important;
  }
  section.troo_da_how_can_help_u_wrapper.our_blogs_wrapper .troo_da_blog_box {
    margin: 0 auto !important;
  }
  .detail_outer.d-flex {
    flex-direction: column;
  }
}
