@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Playfair+Display:wght@400;700&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.228;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  color: #1f1e23;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.33;
  font-weight: 700 !important;
  font-family: "Playfair Display", serif;
}
h1,
.h1 {
  font-size: 50px !important;
}
h2,
.h2 {
  font-size: 36px !important;
}
h3,
.h3 {
  font-size: 28px !important;
}
h4,
.h4 {
  font-size: 24px !important;
}
h5,
.h5 {
  font-size: 20px !important;
  line-height: 1.22 !important;
  margin-bottom: 0 !important;
}
.FontH1 h1 {
  font-size: 60px;
  line-height: 1.33;
  font-family: "Playfair Display", serif;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}
p {
  margin-bottom: 0 !important;
  color: #1f1e23;
}
* + h1,
* + h2 {
  margin-top: 30px;
}
* + h3,
* + h4 {
  margin-top: 25px;
}
* + h5,
* + h6 {
  margin-top: 21px;
}
* + p {
  margin-top: 20px;
}
ul,
ol {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: #fc7523;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
}
a:hover {
  color: #1f1e23;
}
button.btn.btn-primary {
  padding: 20px 40px 19px 41px;
  background-color: #fc7523;
  color: #ffffff;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.22;
  display: inline-block;
  font-weight: 700;
  border: 1px solid transparent;
  font-family: "Montserrat", sans-serif;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0;
  left: -3px;
}
button.btn.btn-primary:hover {
  background: transparent;
  box-shadow: 0px 10px 36px rgba(252, 117, 35, 0.5),
    0px 0px 0px 1px rgba(0, 0, 0, 0.06);
  border: 1px solid #fc7523;
  color: #fc7523;
}
.container {
  max-width: 1240px !important;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px !important;
}

@media (max-width: 991px) {
  h1 {
    font-size: 40px;
    line-height: 1.2;
  }
  h2 {
    font-size: 30px;
  }
  .navbar-toggler:focus {
    box-shadow: none;
    border: none;
  }
  .FontH1 h1 {
    font-size: 37px;
    line-height: 1.1;
  }
}

@media (max-width: 767px) {
  button.btn.btn-primary {
    padding: 15px 20px;
    font-size: 14px;
  }
  .container {
    padding: 0 8px !important;
  }
}

