.coming_soon_wrapper {
  background: #1f1e23 url("../../images/coming_soon_bg.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding-top: 93px;
  padding-bottom: 71px;
  position: relative;
}
.coming_soon_title h2 {
  color: #ffffff;
}
.coming_soon_title p {
  color: #fff;
  margin-top: 22px;
}
.coming_soon_left_part {
  max-width: 514px;
  width: 100%;
}
.coming_soon_mail_form form {
  padding-top: 24px;
}
.coming_soon_mail_form_title h5 {
  color: #fff;
  padding-bottom: 10px;
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}
.coming_soon_mail_form {
  padding-top: 24px;
}
.coming_soon_wrapper form {
  padding-top: 10px;
  max-width: 457px;
  width: 100%;
  position: relative;
}
.form_coming_Soon_inner {
  max-width: 475px;
  width: 100%;
  background: #fff;
  justify-content: space-between;
}
.mail_box_submit.coming_soon_submit button.btn.btn-primary {
  padding: 8px 17px 9px 17px;
}
.mail_box_submit.coming_soon_submit {
  position: absolute;
  right: 11px;
  top: 17px;
}
.coming_Soon_btn button.btn.btn-primary {
  padding: 20px 24px 19px 23px;
}
.coming_Soon_btn {
  padding-top: 42px;
}
.coming_soon_img img {
  width: 449px;
  height: 381px;
  object-fit: cover;
}
.coming_soon_img {
  max-width: 449px;
  width: 100%;
  margin-left: auto;
}
.coming_soon_left_part {
  position: relative;
}
.coming_soon_left_part::after {
  content: "";
  background: url("../../images/coming_wave.png");
  width: 114px;
  height: 59px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 5%;
  right: -28%;
}

@media (max-width: 1140px) {
  .coming_soon_left_part::after {
    position: absolute;
    top: -34px;
    right: 0;
  }
}

@media (max-width: 991px) {
  .coming_soon_img img {
    height: 100%;
    object-fit: contain;
  }
}
