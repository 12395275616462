.troo_da_we_service_wrapper {
  padding-top: 101px;
  padding-bottom: 100px;
}
.troo_da_we_service_left_img img {
  width: 581px;
  height: 545px;
  object-fit: cover;
}
.hero_small_detail.we_service_small_title p {
  color: #1f1e23;
}
.service_title {
  padding-top: 21px;
}
.service_content {
  padding-top: 21px;
  padding-bottom: 40px;
  max-width: 555px;
  width: 100%;
}
.troo_da_we_service_right_detail {
  margin-left: 45px;
}
.discount_detail_outer {
  padding-left: 14px;
  padding-top: 13px;
}
.discount_detail_outer p {
  margin-top: 10px;
  max-width: 467px;
  width: 100%;
}
.service_some_detail_outer {
  padding-bottom: 43px;
}
.discount_outer {
  width: 50px;
  height: 50px;
  background: #fdf0e6;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.service_btn button.btn.btn-primary {
  padding: 20px 32px 19px 31px;
}

@media (max-width: 1140px) {
  .troo_da_we_service_right_detail {
    margin-left: 60px;
  }
  .troo_da_we_service_left_img {
    max-width: 480px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .troo_da_we_service_left_img img {
    height: 100%;
    object-fit: contain;
  }
  .troo_da_we_service_left_img {
    max-width: 581px;
    width: 100%;
    margin: 0 auto;
  }
  .troo_da_we_service_right_detail {
    margin-left: 0;
    padding-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .service_btn {
    width: 100%;
    text-align: center;
  }
  .service_title {
    padding-top: 10px;
    width: 100%;
    text-align: center;
  }
  .discount_detail_outer {
    padding-top: 0;
  }
  .service_content {
    text-align: center;
    padding-bottom: 25px;
  }
  
}
