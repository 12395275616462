.our_contct-2_form_wrapper .form_box.book_table_form_box {
  max-width: 994px;
  width: 100%;
  margin: 0 auto;
}
.our_contct-2_form_wrapper form .form-control {
  max-width: 482px;
  width: 100%;
}
.form-group.txtara textarea.form-control {
  width: 100%;
  max-width: 100%;
  height: 132px;
}
.form_submit_btn.our_book_tble_submit.contct-2.text-center
  button.btn.btn-primary {
  padding: 20px 25px 19px 24px;
}
.our_contct-2_form_wrapper {
  padding-top: 99px;
}
.client_say_section_wrapper.contct-2-small-sec.text-center {
  padding-bottom: 30px;
}
.map_wrapper {
  padding-top: 95px;
}
.map_wrapper .hero_small_detail.we_service_small_title.m-auto {
  max-width: 235px;
  width: 100%;
}
iframe {
  max-width: 790px;
  width: 100%;
}
.map_right_box {
  background: url("../../images/map_img.png");
  max-width: 380px;
  height: 100%;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 23px 10px 64px 35px;
}
.map_title p {
  font-family: "Playfair Display";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}
.map_title .hero_small_detail p {
  color: #fff;
  max-width: 100%;
  width: 100%;
}
.map_title .hero_small_detail {
  color: #fff;
  max-width: 100%;
  width: 100%;
}
.map_right_box .hero_small_detail::after {
  content: "";
  background: url("../../images/hero_img_spoon.png");
  width: 61px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 38px;
  right: 0;
  left: 0;
}
.map_private_detail {
  padding-top: 46px;
}
.map_private_detail address {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
.call-detail-span a {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
}
.call-detail-span {
  display: flex;
  flex-direction: column;
}
.troo-da-connect-together-form-detail-call-box.d-flex {
  padding-bottom: 30px;
}
.call-detail {
  padding-left: 12px;
}
.map_outer {
  max-width: 790px;
  width: 100%;
  height: 100%;
}
.our_contct-2_form_wrapper .form_box.book_table_form_box form .form-control {
  max-width: 482px;
  width: 100%;
}
.our_contct-2_form_wrapper textarea {
  max-width: 981px !important;
  width: 100%;
}

@media (max-width: 1300px) {
  .map_outer {
    max-width: 750px;
  }
  .map_right_box {
    max-width: 360px;
  }
  .our_contct-2_form_wrapper .form_box.book_table_form_box form .form-control {
    max-width: 430px;
    width: 100%;
  }
  .our_contct-2_form_wrapper textarea {
    max-width: 926px !important;
    width: 100%;
  }
}

@media (max-width: 1140px) {
  .map_right_box {
    max-width: 300px;
    padding: 23px 10px 57px 22px;
  }
}

@media (max-width: 991px) {
  .map_wrapper {
    padding-top: 50px;
  }
  .map_outer {
    margin: 0 auto;
  }
  .map_right_box {
    max-width: 100%;
  }
  .map_wrapper .row {
    flex-direction: column-reverse;
  }
  .map_right_box {
    margin-bottom: 15px;
  }
  .our_contct-2_form_wrapper {
    padding-top: 50px;
  }
  .our_contct-2_form_wrapper .form_box.book_table_form_box form .form-control {
    max-width: 100%;
    width: 100%;
  }
  .our_contct-2_form_wrapper textarea {
    max-width: 100%;
    width: 100%;
  }
  .client_say_section_wrapper.contct-2-small-sec.text-center {
    padding-bottom: 0;
  }
}
