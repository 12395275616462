.blog_date_outer {
  padding-top: 25px;
  padding-bottom: 16px;
}
ol.breadcrumb.blog_detail {
  max-width: 484px;
  width: 100%;
}
.admin {
  position: relative;
}
.date {
  padding-right: 14px;
}
.admin p {
  padding-left: 27px;
  padding-right: 7px;
}
.categories_recipes p {
  padding-left: 15px;
}
.admin::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fc7523;
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 100%;
}
.categories_recipes {
  position: relative;
}
.categories_recipes::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fc7523;
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 100%;
}
.blog_detail_left_outer img {
  width: 700px;
  height: 343px;
  object-fit: cover;
}
.blog_detail_left_outer h4 {
  font-family: "Playfair Display";
  font-weight: 700;
}
.blog_detail_left_outer h4 {
  font-family: "Playfair Display";
  font-weight: 700;
  margin: 0;
}
.blog_inner_cls p {
  margin-top: 14px;
}
.blog_inner_cls p + p {
  margin-top: 9px;
}
.blog_inner_cls h5 {
  margin: 0;
  margin-top: 8px;
}
.blog_inner_cls h5 + p {
  margin-top: 17px;
}
.blog_inner_cls ul {
  padding-top: 10px;
}
.blog_inner_cls ul li {
  position: relative;
}
.blog_inner_cls li {
  padding-bottom: 8px;
  padding-left: 45px;
}
.blog_inner_cls ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  background: #1f1e23;
  position: absolute;
  top: 5px;
  left: 24px;
}
.blog_social_icon {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding-top: 22px;
  padding-bottom: 19px;
}
.blog_social_icon .fa {
  color: #1f1e23;
  border: 1px solid #1f1e23;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  margin-right: 11px;
}
.blog_social_icon .fa:hover {
  background-color: #fc7523;
  color: #fff;
  border: 1px solid transparent;
}
.blog_social_icon span {
  font-weight: 700;
}
.blog_social_icon ul {
  padding-left: 10px;
  margin: 0;
}
.comnts h4 {
  color: #002347;
  font-family: "Playfair Display";
  font-weight: 700;
}
.comnts p {
  color: #002347;
  margin-top: 27px;
}
.comnts {
  padding-top: 27px;
}
.blog_detail_form_inner input.form-control {
  max-width: 100%;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ececec;
}
.blog_detail_form_inner form {
  padding-top: 27px;
}
.blog_detail_form_inner textarea.form-control {
  border: 1px solid #ececec;
}
.blog_detail_form_inner ::placeholder {
  color: #c9c9c9 !important;
}
.form_submit_btn.blog_detail {
  max-width: fit-content;
  padding: 0;
  margin-top: 29px;
  margin-bottom: 31px;
}
.form_submit_btn.blog_detail button.btn.btn-primary {
  padding: 15px 36px 14px 36px;
}
.blog_inner_detail_wrapper .form-check-input[type="checkbox"] {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 0;
  border: 1px solid #ececec;
}
.blog_inner_detail_wrapper .form-check-input:checked[type="checkbox"] {
  background: #fc7523 url("../../images/check.svg");
}
.blog_inner_detail_wrapper .form-check-input:focus {
  border-color: #fc7523;
  outline: 0;
  box-shadow: 0px 7px 29px rgb(252 117 35 / 30%);
}
.blog_inner_detail_wrapper {
  padding-top: 126px;
}
label.form-check-label {
  padding-left: 12px;
  padding-top: 6px;
}
.row.blog {
  flex-wrap: nowrap !important;
}
.blog_right_detail {
  margin-left: 79px;
}
.blog_right_detail .form_outer_sign_up {
  max-width: 380px;
  width: 100%;
  height: 50px;
}
.blog_right_detail button.btn.btn-primary {
  padding: 14px 14px;
  height: 52px;
}
.blog_right_detail form {
  padding: 0;
}
.blog_right_detail button.btn.btn-primary:hover {
  background: #fc7523;
}
.post-title {
  padding-bottom: 28px;
  padding-top: 23px;
}
.blog_populer_box {
  max-width: 380px;
  border: 1px solid #ececec;
  width: 100%;
  padding: 15px 20px 18px 20px;
}
.blog_populer_cate_outer_list_outer {
  border-bottom: 1px solid #ececec;
  padding-bottom: 18px;
  padding-top: 16px;
}
.blog_populer_cate_outer_title {
  border-bottom: 1px solid #ececec;
  padding-bottom: 16px;
}
.blog_populer_cate_outer_list_outer:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.post_blog_discount_img {
  background: url("../../images/post_discount.png");
  max-width: 380px;
  width: 100%;
  height: 369px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  margin-top: 30px;
}
.post_blog_img_contnt h4 {
  color: #fff;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin: 0;
  position: relative;
}
.post_blog_img_contnt h4::after {
  content: "";
  width: 50px;
  height: 2px;
  background: #fff;
  position: absolute;
  bottom: -17px;
  left: 87px;
}
.post_blog_img_contnt span {
  color: #fc7523;
}
.post_blog_img_contnt {
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 122px 0;
  height: 100%;
}
button.book_now_btn {
  background: transparent;
  border: 1px solid transparent;
  color: #fc7523;
  font-weight: 700;
}
.book_now_btn {
  padding-top: 22px;
}
.post_img {
  width: 100px;
}
.post_content {
  width: calc(100% - 100px);
  padding-left: 19px;
}
.post_outer {
  padding-bottom: 30px;
}
.post_content_title {
  max-width: 234px;
  width: 100%;
}
.post_date {
  padding-bottom: 14px;
}
ol.breadcrumb.blog_detail li.breadcrumb-item.active {
  margin-right: 15px;
}
.troo_da_blog_box:hover .troo_da_blog_box_date p {
  color: #fff;
}
.troo_da_blog_box_date {
  position: relative;
}
.troo_da_blog_box_date:last-child:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fc7523;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100%;
}
.troo_da_blog_box_date:last-child {
  padding-left: 26px;
}
.form_box.book_table_form_box form .form-control {
  max-width: 379px;
  width: 100%;
}

@media (max-width: 1235px) {
  .blog_right_detail {
    margin-left: 45px;
  }
  section.blog_inner_detail_wrapper .col-lg-7 {
    width: 50%;
  }
  .troo_da_blog_box {
    max-width: 380px;
    min-height: 340px;
  }
}

@media (max-width: 991px) {
  section.blog_inner_detail_wrapper .col-lg-7 {
    width: 100%;
  }
  .blog_right_detail {
    margin-left: 0;
  }
  .row.blog {
    flex-wrap: wrap !important;
  }
  .blog_inner_detail_wrapper {
    padding-top: 50px;
  }
  .post_outer.d-flex {
    border: 1px solid #ececec;
    padding: 0;
    margin-bottom: 20px;
  }
  .post_content {
    padding-top: 9px;
  }
  .blog_populer_box {
    max-width: 100%;
  }
  .post_blog_discount_img {
    max-width: 100%;
  }
  .blog_detail_left_outer img {
    width: 100%;
  }
  .blog_detail_left_outer h4 {
    text-align: center;
  }
  .blog_inner_cls p {
    text-align: center;
  }
  .blog_inner_cls h5 {
    text-align: center;
  }
  .blog_social_icon {
    justify-content: center;
  }
  .comnts {
    padding-top: 15px;
  }
  .comnts p {
    text-align: center;
    margin-top: 15px;
  }
  .form-check {
    display: flex;
  }
  form.d-flex {
    flex-direction: column;
  }
  .blog_right_detail .form_outer_sign_up {
    height: 50px;
    max-width: 100%;
  }
  .post_content_title h5 {
    font-size: 16px;
  }
  .troo_da_blog_box_date:last-child:before {
    display: none;
  }
  .troo_da_blog_box {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .blog_date_outer.d-flex {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .troo_da_blog_box {
    padding: 20px;
  }
  .admin::after {
    display: none;
  }
  .categories_recipes::after {
    display: none;
  }
  .blog_inner_cls li {
    padding-left: 16px;
  }
  .blog_inner_cls ul li::before {
    left: 0;
  }
}
