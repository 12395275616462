.dish_box {
  max-width: 100%;
  width: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  margin: 15px;
}
.dish-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dish_dettail_outer {
  padding: 13px 20px 19px 25px;
  transition: all 0.3s ease;
}
.dish_title p {
  margin-top: 10px;
}
.dish_price_tag {
  padding-top: 7px;
}
.dish_box:hover .dish_dettail_outer {
  background: #ffffff;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
}
.dish_title h5 {
  white-space: nowrap;
  color: #1f1e23;
}
.dish_box:hover .dish_title h5 {
  color: #fc7523;
}
.dish_box:hover .dish_price_tag p {
  color: #fc7523;
}
.dish_price_tag span {
  font-weight: 700;
}

.troo_da_most_populer_wrapper a {
  text-decoration: none;
}
.menu2 .our_awesome_wrapper {
  padding-top: 117px;
  padding-bottom: 75px;
}

@media (max-width: 1280px) {
  .dish_box {
    max-width: 100%;
    margin: 0 auto;
  }
  .dish_title h5 {
    white-space: break-spaces;
  }
  .dish_dettail_outer {
    padding: 13px 20px 19px 18px;
  }
  .dish_box {
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .dish_box {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .dish_dettail_outer {
    padding: 13px 20px 19px 25px;
    background: #ffffff;
    box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  }
  .Menu_dish_wrapper {
    padding-top: 50px;
  }
  .dish-img img {
    object-fit: contain;
  }
}
