.img_404 {
  text-align: center;
  padding-top: 22px;
}
.About_hero_wrapper.content_404 p {
  color: #fff;
  text-align: center;
  margin-top: 16px;
}
.img_404 img {
  width: 615px;
  height: 366px;
  object-fit: cover;
}
.btn_404 {
  text-align: center;
}
.btn_404 button.btn.btn-primary {
  padding: 20px 23px 19px 24px;
}
.About_hero_wrapper.content_404 {
  background: #1f1e23 url("../../images/404_bg.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
}

@media (max-width: 767px) {
  .img_404 img {
    height: 100%;
    object-fit: contain;
  }
}
