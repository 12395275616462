.restorant_images_wrapper {
  padding-top: 123px;
  padding-bottom: 72px;
}
.restorant_images_title_outer {
  max-width: 549px;
  width: 100%;
}
.restorant_images_title_outer h4 {
  font-family: "Playfair Display";
  margin: 0;
}
.restorant_images_title_outer p {
  margin-top: 9px;
}
.restorant_images_title_outer {
  padding-bottom: 29px;
}
.restorant_images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.restorant_img_2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.restorant_images_3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.restorant_img_4 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.restro_common {
  margin-bottom: 15px;
}

.restorant_images_3.restro_common2 {
  margin-top: 15px;
}
.restorant_img_4.restro_common2 {
  margin-top: 15px;
}
.restorant_video_wrapper {
  padding-bottom: 100px;
}
.restorant_video_gallery_img img {
  width: 584px;
  height: 334px;
  object-fit: cover;
}

.restorant_btn_hover {
  position: absolute;
  top: 45%;
  left: 45%;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.restorant_video_gallery_img_outer {
  position: relative;
  transition: all 0.3s ease-in;
  cursor: pointer;
  max-width: 583px;
  width: 100%;
  margin-bottom: 30px;
}
.restorant_video_gallery_img_outer > .restorant_btn_hover {
  z-index: 1;
}
.restorant_btn_hover p {
  margin-top: 12px;
  font-family: "Playfair Display";
  font-weight: 700;
  color: #ffffff;
  margin-left: -13px;
}
.restorant_video_gallery_img {
  position: relative;
  transition: all 0.3s ease-in;
}
.restorant_video_gallery_img_outer:hover:after {
  content: "";
  background: #1f1e23;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
}
.restorant_video_gallery_img_outer:hover .restorant_btn_hover {
  opacity: 1;
  transition: all 0.3s ease-in;
}
.restorant_btn_hover button {
  background: transparent;
  border: none;
}

/* modal */
/* .no-scroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
  z-index: 99999999999;
} */
.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  z-index: 99999999999;
}

.modal__align {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal__content {
  width: 800px;
  height: 500px;
  box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
    0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
    0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
    0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
  border-radius: 20px;
  background: transparent;
  color: #000;
  margin: 0rem 4rem;
}

.modal__close {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  bottom: 50px;
  width: 32px;
  height: 32px;
  padding: 0;
}

.modal__video-align {
  display: flex;
  position: relative;
  bottom: 37px;
}

.modal__video-style {
  border-radius: 20px;
  z-index: 100;
}

.modal__spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal__spinner {
  animation: spin 2s linear infinite;
  font-size: 40px;
  color: #1b6aae;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 800px) {
  .modal__content {
    margin: 0rem 1rem;
    width: 100%;
  }
  .modal__video-style {
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .modal__content {
    background: transparent;
    height: auto;
  }
  .modal__video-align {
    bottom: 0px;
  }
  .modal__viineer1deo-style {
    height: auto;
  }
}


@media (max-width: 1024px) {
  .restro_common {
    margin: 0px;
  }
  .restorant_images img {
    height: 100%;
    object-fit: contain;
  }
  .restorant_img_2 img {
    height: 100%;
    object-fit: contain;
  }
  .restorant_img_4 img {
    height: 100%;
    object-fit: contain;
  }
  .restorant_images_3 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .restorant_img_4.restro_common2 {
    margin-left: 0;
    margin-top: 15px;
  }
  .restorant_images_3.restro_common2 {
    margin-left: 0;
    margin-top: 15px;
}
.restorant_images_wrapper .row {
  margin: 0 0 !important;
}
.restorant_video_gallery_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}
@media (max-width: 991px) {

  .restorant_video_wrapper .row {
    margin: 0 0 !important;
  }
  
  .restorant_images.restro_common {
    text-align: center;
  }
  .restorant_img_2.restro_common {
    text-align: center;
  }
  .restorant_images_3.restro_common2 {
    margin-left: 0;
    text-align: center;
  }
  .restorant_img_4.restro_common2 {
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
  }
  .restorant_images_title_outer {
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .restorant_images_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .restorant_video_gallery_img_outer {
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .restorant_video_wrapper {
    padding-bottom: 50px;
  }
  .restorant_images_title_outer {
    padding-bottom: 15px;
  }
}
