header {
  background: #fdf0e6;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 999;
  position: relative;
  position: sticky;
  top: 0;
  padding: 10px 0;
}
ul.navbar-nav a.nav-link {
  color: #1f1e23;
  font-size: 16px;
  line-height: 1.228;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease;
}
ul.navbar-nav .nav-item a.nav-link:hover {
  color: #fc7523;
}
ul.navbar-nav .nav-item.active a.nav-link {
  color: #fc7523;
}
header ul li svg {
  color: #1f1e23;
}
.nav-item svg {
  margin-right: 0;
  margin-left: 5px;
}
.header_btn {
  margin-left: auto;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 !important;
}
.navbar-brand a {
  padding: 0 !important;
  margin-right: 114px;
}
.header_Logo img {
  width: 255px;
  height: 44px;
  object-fit: cover;
}
.dropdown-toggle::after {
  content: "";
  background: url("../../images/Header_drop_down.png");
  width: 9px;
  height: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: -15px;
}
.dropdown-toggle {
  position: relative;
}
li.nav-item {
  margin-right: 30px;
  position: relative;
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
li.nav-item:hover::after {
  content: "";
  background: url("../../images/header_hover_spoon.png");
  width: 20px;
  height: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
li.nav-item.active::after {
  content: "";
  background: url("../../images/header_hover_spoon.png");
  width: 20px;
  height: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
ul.dropdown-menu a.dropdown-item::before {
  content: "";
  background: transparent;
  width: 20px;
  height: 6px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 23px;
  left: 7px;
  border-radius: 100%;
  transition: all 0.3s ease;
}
ul.dropdown-menu a.dropdown-item.dropdownActive::before {
  content: "";
  background: url("../../images/header_hover_spoon.png");
}

ul.dropdown-menu {
  background: #ffffff;
  border-radius: 8px;
  border: none;
}
ul.dropdown-menu a.dropdown-item {
  color: #022539;
  font-weight: 500;
  line-height: 1.21;
  font-family: "Inter", sans-serif;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
ul.dropdown-menu a.item-uppercase {
  text-transform: uppercase !important;
}
ul.dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  min-width: 220px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
ul.dropdown-menu a.dropdown-item:hover {
  color: #fb991c;
  background-color: #36353a;
  padding-left: 33px;
}
ul.dropdown-menu a.dropdown-item.dropdownActive {
  color: #fb991c;
  background-color: #36353a !important;
  padding-left: 40px;
  border: 1px solid #ccc;
  position: relative;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: initial !important;
}
.header_btn button.btn.btn-primary {
  font-weight: 500;
  padding: 17px 27px 17px 27px;
}
.navbar-toggler:focus{
  box-shadow: none !important;
}
@media (max-width: 1235px) {
  li.nav-item {
    margin-right: 25px;
  }
  .navbar-brand a {
    margin-right: 30px;
  }
  .navbar-brand img {
    width: 240px;
    object-fit: contain;
  }
}

@media (max-width: 1140px) {
  .navbar-brand a {
    margin-right: 30px;
  }

  li.nav-item {
    margin-right: 17px;
}
}

@media (max-width: 1030px) {
  .navbar-brand a {
    margin-right: 10px;
  }
  li.nav-item {
    margin-right: 15px;
  }
  .navbar-brand img {
    width: 220px;
    object-fit: contain;
  }
}
@media (max-width: 991px) {
  ul.navbar-nav {
    justify-content: center;
    align-items: center;
  }
  li.nav-item {
    margin-right: 0;
    padding: 20px 0;
}
  .header_btn {
    margin: 0 auto;
    text-align: left;
    margin-top: 20px;
  }
  li.nav-item:hover::after {
    display: none;
  }
  .dropdown-menu.dropdown-primary.show {
    margin-top: 10px;
  }
  .navbar-toggler-icon{
    background-image: url("../../images/Hedaer_Responsive_icon.svg") !important;
  }
  header .navbar-toggler {
    padding: 10px 15px;
    font-size: 17px;
    line-height: 1;
    background-color: #fc7523;
    border: 1px solid transparent;
    border-radius: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
  .navbar-toggler-icon {
    background-image: none;
  }
  li.nav-item {
    text-align: center;
    position: relative;
  }
  .navbar-nav li a:hover {
    color: #fff;
    padding: 10px 0;
  }
  a.dropdown-item {
    background-color: #fdf0e6;
    border-bottom: 2px solid #fc7523;
  }
  .dropdown-menu.dropdown-primary.show {
    margin-top: 10px;
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    margin-top: 16px;
  }
  ul.navbar-nav a.nav-link {
    padding-top: 10px;
  }
  .dropdown-menu.dropdown-primary.show {
    left: 0;
    top: 26px;
  }

  .navbar-expand-lg .navbar-collapse.show {
    top: 125%;
    width: 100%;
    padding: 20px;
    z-index: 999;
}
ul.dropdown-menu.show {
  top: 50px;
  left: 0;
}
ul.navbar-nav li.nav-item {
  position: relative;
  width: 100%;
  display: block;
}
ul.dropdown-menu{
  box-shadow: none;
}
ul.navbar-nav {
  margin-left: 0;
  margin-right: 0;
  align-items: baseline;
}
ul.navbar-nav a.nav-link {
  padding: 15px 0 !important;
  text-align: left;
  color: #232323 !important;
}
li.nav-item.active::after{
  display: none;
}
 
}

@media (max-width: 600px) {
  .header_Logo img {
    width: 190px;
  }
}

@media (max-width: 630px) {
  a.navbar-brand {
    margin-right: 0;
  }
  .navbar-brand img{
    width: 200px;
  }
  header{
    padding: 0;
  }
  header .navbar-toggler{
    padding: 5px;
  }
  .header_Logo img {
    width: 170px;
    height: 100%;
  }
}
