.troo_da_why_people_choose_wrapper {
  background: url("../../images/people_choose_us_bg_img.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
  padding-top: 101px;
  padding-bottom: 100px;
  overflow: hidden;
}
.overlay {
  background: #1f1e23;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.troo_da_why_people_choose_wrapper > .container {
  position: relative;
  z-index: 2;
}
.why_people_choose_us_title_outer h2 {
  color: #ffffff;
  padding-top: 21px;
}
.why_people_choose_box {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  padding: 39px 24px 30px 24px;
  text-align: center;
  max-width: 273px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  margin: 15px;
}
.why_people_choose_img {
  width: 100px;
  height: 100px;
  background: #1f1e23;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.why_people_choose_title {
  padding-top: 28px;
}
.why_people_choose_content {
  padding-top: 13px;
  max-width: 224px;
  width: 100%;
}
.why_people_choose_box:hover .why_people_choose_img {
  background: #fc7523;
  box-shadow: 0px 10px 36px rgba(252, 117, 35, 0.5),
    0px 0px 0px 1px rgba(0, 0, 0, 0.06);
}
.why_people_choose_box:hover .why_people_choose_title h5 {
  color: #fc7523;
}
.why_people_choose_box:hover:after {
  content: "";
  background: url("../../images/why_choose_hover_img.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 245px;
  position: absolute;
  bottom: -38px;
  left: 0;
  right: 0;
  overflow: hidden;
}
.why_people_choose_us_title_outer {
  padding-bottom: 48px;
}
.troo_da_why_people_choose_wrapper .row {
  margin: 0 -15px !important;
}

/* menu highlight */

@media (max-width: 1120px) {
  .troo_da_why_people_choose_wrapper .row {
    margin: 0;
  }
}

@media (max-width: 1140px) {
  .why_people_choose_box {
    max-width: 230px;
  }
}

@media (max-width: 1170px) {
  .why_people_choose_box {
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
  }
  .why_people_choose_box:hover:after {
    bottom: -71px;
  }
  .why_people_choose_box {
    max-width: 230px;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .why_people_choose_box {
    max-width: 273px;
    width: 100%;
  }
  .troo_da_why_people_choose_wrapper {
    max-width: 1470px;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
  }
  .why_people_choose_us_title_outer {
    padding-bottom: 20px;
  }
  .troo_da_why_people_choose_wrapper .row {
    margin: 0 0 !important;
  }
  .troo_da_why_people_choose_wrapper .col-lg-3 {
    margin-bottom: 20px;
  }
}
