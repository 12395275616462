.troo_da_most_populer_wrapper {
  padding-top: 95px;
  padding-bottom: 100px;
  overflow: hidden;

}
.menu_box {
  max-width: 100%;
  width: 100%;
  padding-bottom: 29px;
}
.menu_dish_name_outer::after {
  content: "";
  border: 1px dashed #1f1e23;
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: -1;
}
.menu_dish_name_outer.d-flex.justify-content-between {
  position: relative;
}
.menu_dish_name {
  position: relative;
  background-color: #fff;
}
.menu_content {
  padding-top: 10px;
}
.most_popular_dishes_title {
  padding-bottom: 46px;
}
.menu_outer {
  max-width: 100%;
  width: 100%;
  margin: 0 15px;
}
.menu_dishes_img {
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
}
.menu_dishes_img img {
  width: 380px;
  height: 603px;
  object-fit: cover;
}
.troo_da_most_populer_wrapper .row {
  margin: 0 -15px !important;
}
.show_full_menu_btn {
  padding-top: 50px;
}
.show_full_menu_btn button.btn.btn-primary {
  padding: 20px 26px 19px 26px;
}
.menu_dish_price {
  background: #fff;
}

/* menu page */
.troo_da_most_populer_wrapper.our_menu_most_populer .menu_outer {
  max-width: 579px;
  width: 100%;
  margin: 0 0;
}
.troo_da_most_populer_wrapper.our_menu_most_populer .menu_box {
  max-width: 579px;
}
.troo_da_most_populer_wrapper.our_menu_most_populer.troo_da_most_populer_wrapper
  .row {
  margin: 0 0 !important;
}
.why_people_choose_us_title_outer.our_menu_box.text-center {
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
}
.why_people_choose_us_title_outer.our_menu_box p {
  color: #fff;
  max-width: 426px;
  width: 100%;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: 700;
}
.why_people_choose_us_title_outer.our_menu_box span {
  color: #fc7523;
}
section.troo_da_why_people_choose_wrapper.about_us_why_choose.our_menu {
  background: url("../../images/fresh_Our_img.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
}

@media (max-width: 1235px) {
  .menu_outer {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 1280px) {
  .troo_da_most_populer_wrapper.our_menu_most_populer .menu_outer {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .troo_da_most_populer_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .menu_outer {
    max-width: 100%;
  }
  .troo_da_most_populer_wrapper .row {
    margin: 0 0 !important;
  }
  .menu_dishes_img {
    margin-bottom: 30px;
  }
  .show_full_menu_btn {
    padding-top: 15px;
  }
  .troo_da_most_populer_wrapper.our_menu_most_populer.our_menu_2.our_menu_dish_detail
    .most_popular_dishes_title {
    padding-bottom: 35px;
    padding-left: 0;
  }
  .troo_da_most_populer_wrapper {
    padding-top: 80px;
  }
  .troo_da_most_populer_wrapper.our_menu_most_populer.our_menu_2 .col-lg-3 {
    width: 50%;
  }
  .most_popular_dishes_title {
    padding-bottom: 30px;
  }
  .menu_box {
    padding-bottom: 20px;
  }
  .most_popular_dishes_title {
    padding-bottom: 20px;
  }
  .troo_da_most_populer_wrapper.our_menu_most_populer .menu_box {
    max-width: 579px;
    margin: 0 auto;
  }
  .troo_da_most_populer_wrapper.our_menu_most_populer .menu_outer {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

}

@media (max-width: 600px) {
  .menu_dishes_img img {
    height: 100%;
    object-fit: contain;
  }
  .menu_box {
    padding-bottom: 20px;
  }
}

@media (max-width: 630px) {
  .troo_da_most_populer_wrapper.our_menu_most_populer.our_menu_2 .col-lg-3 {
    width: 100%;
  }
}
