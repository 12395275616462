.download_wrapper {
  background: url("../../images/downLoad_App_bg.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 70px;
}
.downLoad_overlay {
  width: 100%;
  height: 100%;
  background: #1f1e23;
  opacity: 0.9;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.download_wrapper > .container {
  position: relative;
  z-index: 2;
}
.download_left_img{
  text-align: center;
}
.download_left_img img {
  width: 468px;
  height: 500px;
  object-fit: cover;
}
.FontH1 h2 {
  color: #fff;
  font-size: 50px !important;
}
.download_content p {
  color: #fff;
}
.download_content {
  padding-top: 43px;
}
.device_outer {
  padding-top: 40px;
}
.download_left_detail {
  max-width: 454px;
  width: 100%;
}
.google_play {
  padding-right: 14px;
}
.google_play img {
  width: 155px;
  height: 52px;
  object-fit: cover;
}
.app_store img {
  width: 156px;
  height: 52px;
  object-fit: cover;
}

@media (max-width: 991px) {
  .download_wrapper .row {
    flex-direction: column-reverse;
  }
  .download_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .download_left_img {
    text-align: center;
  }
  .download_left_detail {
    max-width: 454px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .download_left_img img {
    width: 468px;
    height: 100%;
    object-fit: contain;
  }
  .app_store img {
    height: 100%;
    object-fit: contain;
  }
  .google_play img {
    height: 100%;
    object-fit: contain;
  }
}
