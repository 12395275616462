.form_wrapper {
  background: url("../../images/form_outer_bg.png");
  width: 100%;
  height: 500px;
  padding-top: 104px;
  padding-bottom: 100px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}
.form_content_1 {
  padding-top: 18px;
}
.form_content_2 {
  padding-top: 10px;
}
.form_note {
  padding-top: 20px;
}
.form_title h4 {
  color: #fc7523;
  margin: 0;
  font-family: "Playfair Display";
}
.form_note span {
  font-weight: 700;
}
.form_box {
  background: #ffffff;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  border-radius: 8px;
  padding: 43px 30px 50px 30px;
  margin-top: -210px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.form_box::before {
  content: "";
  background: url("../../images/form_2_bg.png");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  bottom: 232px;
  left: -11px;
}
.form_box::after {
  content: "";
  background: url("../../images/form_1_bg.png");
  width: 52px;
  height: 52px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 55px;
  right: -22px;
}
.form_box_title {
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  max-width: 212px;
  width: 100%;
}
.form_box_title h4 {
  margin-top: 6px;
  margin-bottom: 11px;
}
form {
  padding-top: 47px;
}
form label {
  color: #1f1e23;
  font-size: 14px;
  line-height: 1.22;
  margin-bottom: 12px;
}
form .form-control {
  border: 1px solid #c9c9c9;
 
  width: 100%;
  height: 50px;
}
form .row {
  margin-bottom: 13px !important;
}
form .form-control:focus {
  border: 2px solid #fc7523;
}
.form_submit_btn {
  padding-top: 17px;
}
.form_submit_btn button.btn.btn-primary {
  width: 100%;
}
.form_bottom_layer {
  position: absolute;
  bottom: -20px;
  left: 157px;
}
.form_detail {
  position: relative;
}
.form_detail::after {
  content: "";
  background: url("../../images/form_vector.png");
  width: 114px;
  height: 59px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -58px;
  right: 58px;
}

/* form2 */
.brand_logo_wrapper.our_contact_us {
  padding-top: 27px;
}
.form_box.book_table_form_box {
  margin: 0;
  box-shadow: none;
  padding: 0;
  background: transparent;
}
.form_wrapper.book_table_form_wrapper {
  padding-top: 95px;
  padding-bottom: 122px;
  background: url("../../images/book_tble_bg.png");
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.form_box.book_table_form_box::after {
  display: none;
}
.form_box.book_table_form_box::before {
  display: none;
}
.form_submit_btn.our_book_tble_submit button.btn.btn-primary {
  width: auto;
  padding: 20px 20px 19px 21px;
}
.book_table_right_side_img {
  position: relative;
}
.book_table_right_side_inner_img {
  position: absolute;
  top: 0;
}
.book_table_right_side_inner_img img {
  width: 451px;
  height: 622px;
  object-fit: contain;
}

@media (max-width: 1300px) {
  .form_box.book_table_form_box form .form-control {
    max-width: 350px;
  }
}

@media (max-width: 1235px) {
 
  .book_table_right_side_inner_img img {
    object-fit: contain;
  }
}

@media (max-width: 1170px) {
  .form_box.book_table_form_box form .form-control {
    max-width: 300px;
  }
}


@media (max-width: 991px) {
  .form_detail::after {
    display: none;
  }
  .form_box {
    margin-top: 35px;
  }
  .form_wrapper {
    max-width: 1470px;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  form {
    padding-top: 20px;
  }
  .form_detail {
    text-align: center;
  }
  .form_box.book_table_form_box form .form-control {
    max-width: 100%;
  }
  .book_table_right_side_inner_img img {
    height: 100%;
  }
  .form_box.book_table_form_box .col-lg-6 {
    padding-top: 15px;
  }
}
