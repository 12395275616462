.mail_box_wrapper .row {
  background: #ffffff;
  box-shadow: 0px 7px 29px rgb(252 117 35 / 30%);
  padding: 63px 72px 62px 51px !important;
}
footer {
  background: #1f1e23 url("../../images/footer_bg.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding-top: 186px;
  padding-bottom: 20px;
  display: block;
}
footer a {
  text-decoration: none;
}
.footer_menu_bottom_list_outer ul{
  padding-left: 0;
}
.mail_box_left {
  max-width: 451px;
  width: 100%;
}
.mail_box_left h5 {
  font-weight: 400;
}
.mail_box_submit button.btn.btn-primary {
  white-space: nowrap;
  padding: 15px 22px 15px 21px;
}
.mail_box_wrapper {
  padding-top: 37px;
  margin-bottom: -85px;
  z-index: 3;
  position: relative;
}

.footer_title h3 {
  color: #ffffff;
}
.footer_content p {
  color: #fc7523;
  font-weight: 700;
}
.footer_time p {
  color: #ffffff;
}
.footer_title {
  padding-bottom: 17px;
}
.footer_time {
  padding-top: 11px;
}
.footer_content.content-2 {
  padding-top: 19px;
}
.footer_call_1 a {
  color: #fff;
  font-weight: 700;
}
.footer_call_1 {
  padding-bottom: 11px;
}
.footer_call_2 a {
  color: #fff;
  font-weight: 700;
}
.footer_logo {
  padding-top: 35px;
}
.footer_user_mail a {
  color: #fff;
  font-weight: 700;
}
.footer_user_mail {
  padding-bottom: 36px;
}
address {
  margin: 0;
  font-style: normal;
  color: #fff;
  font-weight: 700;
  max-width: 211px;
  width: 100%;
}
.footer_social_icon ul li svg {
  color: #fff;
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.footer_social_icon {
  padding-top: 15px;
}
.footer_social_icon ul{
  padding-left: 0;
}
.footer_social_icon span {
  color: #ffffff;
  margin-right: 7px;
}
ul li svg:hover {
  color: #fc7523;
}
.footer_bottom_menu li a.nav-link {
  color: #fff;
}
.footer_bottom_menu .collapse.navbar-collapse {
  max-width: 688px;
  width: 100%;
  margin: 0 auto;
}
.footer_bottom_menu {
  border-top: 1px solid #333239;
  border-bottom: 1px solid #333239;
  padding-top: 23px;
  padding-bottom: 26px;
  margin-bottom: 20px;
  margin-top: 70px;
}
.footer_right_part p {
  color: #fff;
}
.footer_left_part p {
  color: #fff;
}
.footer_right_part {
  justify-content: end;
  display: flex;
}
.footer_right_part span {
  color: #fff;
  margin-left: 17px;
  font-size: 14px;
  line-height: 1.22;
}
.footer_right_part p {
  margin: 0;
  margin-left: 23px;
  font-size: 14px;
  line-height: 1.22;
  cursor: pointer;
  transition: all 0.3s ease;
}
.footer_left_part a {
  color: #fc7523;
}
.footer_right_part p:hover {
  color: #fc7523;
}
.footer_box.second {
  position: relative;
  z-index: 1;
  text-align: center;
}
.footer_box{
  display: grid;
  justify-content: center;
}
.footer_box.second::after {
  content: "";
  background: url("../../images/footer_Round_shape.png");
  width: 310px;
  height: 310px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: -30px;
  left: 35px;
  z-index: -1;
}
.form_outer_sign_up {
  max-width: 475px;
  width: 100%;
}
.form-control {
  padding: 16px 10px 17px 18px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.22;
  color: #1f1e23;
  background-color: #fff;
  border: 1px solid #c9c9c9;
  border-radius: 0;
}
.form_box select {
  background-image: url("../../images/arrow_drpDown.png");
  background-position: calc(100% - 22px) 22px, calc(100% - 20px) 14px, 100% 0;
  background-size: 10px 6px, 10px 6px;
  background-repeat: no-repeat;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #c9c9c9;
  outline: 0;
  box-shadow: none;
}
input.form-control.sign_up {
  max-width: 451px;
  width: 100%;
  height: 52px;
}
.mail-box-right form {
  padding: 0;
  max-width: 455px;
  width: 100%;
  margin-left: auto;
}
.mail_box_left {
  position: relative;
}
.mail_box_left::after {
  content: "";
  background: url("../../images/mail_box.png");
  width: 106px;
  height: 72px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -10%;
  right: -29%;
}
ul.footer_menu_bottom_list_inner {
  margin: 0;
}
ul.footer_menu_bottom_list_inner li {
  margin-right: 43px;
  transition: all 0.3s ease;
  cursor: pointer;
}
ul.footer_menu_bottom_list_inner li:hover a {
  color: #fc7523;
}
ul.footer_menu_bottom_list_inner li a {
  color: #fff;
  font-weight: 500;
  position: relative;
}
ul.footer_menu_bottom_list_inner li a:hover::after {
  content: "";
  background: url(../../images/header_hover_spoon.png);
  width: 20px;
  height: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: -10px;
  right: 24px;
}

@media (max-width: 1235px) {
  .mail_box_left::after {
    width: 95px;
    right: -20%;
  }
  input.form-control.sign_up {
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    max-width: 100%;
  }
}

@media (max-width: 1170px) {
  .mail_box_left h5 {
    font-weight: 400;
    font-size: 16px;
    max-width: 300px;
    line-height: 1.5;
  }
  .mail_box_left::after {
    right: 0;
  }
  .footer_box.second::after {
   left: 0;
  }

}


@media (max-width: 991px) {
  input.form-control.sign_up {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
  .mail-box-right form {
    padding: 0;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    justify-content: center;
  }
  .mail_box_left {
    margin: 0 auto;
    text-align: center;
  }
  .mail_box_wrapper .row {
    padding: 40px !important;
  }
  .footer_box.second::after {
    display: none;
  }
  .footer_box {
    text-align: center;
  }
  footer .col-lg-4 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .footer_box.second {
    margin-left: 0;
  }
  footer {
    padding-top: 120px;
  }
  .footer_user_mail {
    padding-bottom: 20px;
  }
  .footer_logo {
    padding-top: 20px;
  }
  .footer_right_part {
    justify-content: center;
  }
  .footer_left_part {
    text-align: center;
    padding-bottom: 15px;
  }
  .footer_bottom_menu {
    margin-top: 20px;
  }
  section.mail_box_wrapper form.d-flex {
    justify-content: center;
    align-items: center;
  }
 .mail_box_wrapper .mail_box_submit {
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  ul.footer_menu_bottom_list_inner {
    flex-direction: column;
  }
  ul.footer_menu_bottom_list_inner li {
    margin-right: 0;
    margin-bottom: 20px;
  }

}

@media (max-width: 600px) {
  .mail_box_left h5 {
    max-width: 100%;
    width: 100%;
  }
  .mail_box_wrapper .row {
    padding: 20px !important;
  }
  .mail_box_left::after {
    display: none;
  }
  .mail-box-right form {
    flex-direction: column;
    margin-top: 15px;
  }
  .mail_box_submit button.btn.btn-primary {
    width: 100%;
  }
  .footer_right_part p {
    margin: 0;
    margin-left: 0;
  }
  .footer_right_part span {
    margin-right: 25px;
  }
}
