.troo_da_categories_wrapper {
  background: #fdf0e6;
  position: relative;
  padding-top: 95px;
  padding-bottom: 100px;
  overflow: hidden;
}
.troo_da_categories_wrapper::after {
  content: "";
  background: url("../../images/categories_bg_wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  width: 1246px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.categories_title_outer h2 {
  margin-top: 21px;
}
.categories_inner_number {
  width: 70px;
  height: 70px;
  background: #1f1e23;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.categories_inner_number span {
  font-size: 36px;
  line-height: 1.33;
  color: #ffffff;
  font-weight: 700;
}
.categories_number {
  width: 70px;
}
.categories_detail_outer {
  width: calc(100% - 70px);
  padding-left: 15px;
}
.categories_box {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  padding: 40px 10px 40px 32px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 15px;
  position: relative;
}
.categories-content {
  max-width: 442px;
  width: 100%;
}
.categories_title h4 {
  margin-bottom: 5px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  transition: all 0.3s ease;
}
.categories_title_outer {
  padding-bottom: 41px;
}
.categories_box:hover .categories_inner_number {
  background: #fc7523;
  box-shadow: 0px 10px 36px rgba(252, 117, 35, 0.5),
    0px 0px 0px 1px rgba(0, 0, 0, 0.06);
}
.categories_box:hover .categories_title h4 {
  color: #fc7523;
}
.troo_da_categories_wrapper .row {
  margin: 0 -15px !important;
}
.troo_da_categories_wrapper > .container {
  z-index: 111;
  position: relative;
}
.categories_title_outer .hero_small_detail.we_service_small_title.m-auto {
  max-width: 198px;
  width: 100%;
}
.categories_box:hover:after {
  content: "";
  background: url("../../images/categories_hover_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 604px;
  height: 104px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (max-width: 1170px) {
  .categories_box:hover:after {
    background-size: contain;
    max-width: 604px;
    bottom: -23px;
    right: 0;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .categories_box:hover:after {
    bottom: -27px;
  }
}

@media (max-width: 991px) {
  .categories_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  .categories_number {
    width: 100%;
  }
  .categories_inner_number {
    width: 100%;
  }
  .categories_detail_outer {
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
  .troo_da_categories_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .categories_title_outer {
    padding-bottom: 10px;
  }
  .categories-content {
    margin: 0 auto;
  }
}
