ol.breadcrumb.dish_detail_breadcrumb {
  max-width: 416px;
  width: 100%;
}
.troo_da_most_populer_wrapper.our_menu_most_populer.our_menu_2.our_menu_dish_detail
  .most_popular_dishes_title {
  padding-bottom: 35px;
  padding-left: 15px;
}
.Menu_dish_wrapper {
  padding-top: 115px;
}
.menu_dish_detail2_content {
  padding-top: 25px;
}
.menu_dish_detail2_left {
  max-width: 583px;
  width: 100%;
}
.menu_dish_detail2_content p + p {
  margin-top: 7px;
}
.menu_dish_detail2_title h4 {
  color: #fc7523;
  margin: 0;
  font-family: "Playfair Display";
  font-weight: 700;
}
.menu_dish_detail2_price {
  padding-top: 10px;
  padding-bottom: 14px;
}
.verify_content {
  width: calc(100% - 20px);
  padding-left: 7px;
}
.veryfy_img {
  width: 20px;
}
.menu_dish_detail2_right_content {
  padding-top: 20px;
  padding-bottom: 32px;
}
.menu_dish_detail2_right_content p + p {
  margin-top: 14px;
}
.menu_dish_detail2_food_box {
  background: #fdf0e6;
  max-width: 279px;
  width: 100%;
  padding: 12px 7px 23px 36px;
}
.food_box_title h4 {
  margin: 0;
  font-family: Playfair Display;
  font-weight: 700;
}
.food_box_list ul {
  margin: 0;
  padding-top: 18px;
}
.food_box_list ul li {
  padding-bottom: 26px;
  position: relative;
  padding-left: 15px;
}
.food_box_list ul li::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 0;
  background: #fc7523;
  border-radius: 100%;
}
.menu_dish_detail2_right {
  margin-left: 17px;
}
.col-lg-6.nutri_box_2 .menu_dish_detail2_food_box {
  margin-left: 15px;
}

@media (max-width: 1280px) {
  .menu_dish_detail2_left {
    max-width: 490px;
  }
  .menu_dish_detail2_right {
    margin-left: 20px;
  }
  .menu_dish_detail2_food_box {
    max-width: 240px;
    padding: 12px 7px 23px 24px;
  }
}

@media (max-width: 991px) {
  .menu_dish_detail2_left {
    max-width: 100%;
    text-align: center;
  }
  .menu_dish_detail2_content {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
  }
  .menu_dish_detail2_right {
    margin-left: 0;
    margin-top: 15px;
  }
  .menu_dish_detail2_verify_outer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu_dish_detail2_right_content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .menu_dish_detail2_right_content {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .menu_dish_detail2_food_box {
    max-width: 279px;
    margin: 0 auto;
  }
  .col-lg-6.nutri_box_2 .menu_dish_detail2_food_box {
    margin-left: 0;
    margin: 0 auto;
    margin-top: 15px;
  }
  .menu_dish_detail2_title {
    text-align: center;
  }
  .menu_dish_detail2_price {
    text-align: center;
  }
  .verify_content {
    padding: 0;
    text-align: center;
  }
  ol.breadcrumb.dish_detail_breadcrumb {
    max-width: 350px;
    width: 100%;
    flex-direction: column;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }
  ol.breadcrumb {
    flex-direction: column;
  }
}

@media(max-width:600px){
  ol.breadcrumb.dish_detail_breadcrumb { max-width: 225px; } 
    ol.breadcrumb { padding: 16px 22px 14px 22px; } 
}
