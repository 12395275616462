.row {
  margin: 0 !important;
  padding: 0 !important;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  justify-content: space-between;
}
.troo_da_hero_wrapper {
  background: #1f1e23 url("../../images/hero_bg.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  padding-top: 160px;
  padding-bottom: 221px;
  position: relative;
  margin-top: -50px;
}
.hero_small_detail {
  position: relative;
  max-width: 210px;
  width: 100%;
}
.hero_small_detail::after {
  content: "";
  background: url("../../images/hero_img_spoon.png");
  width: 61px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 3px;
  right: -75px;
}
.hero_small_detail p {
  color: #ffffff;
  font-weight: 700;
}
.hero_title h1 {
  color: #fc7523;
}
.hero_title span {
  color: #ffffff;
}
.hero_content p {
  color: #fff;
}
.hero_title {
  max-width: 540px;
  width: 100%;
  padding-top: 28px;
}
.hero_content {
  padding-top: 27px;
  max-width: 538px;
  width: 100%;
}
.hero_btn_outer {
  padding-top: 27px;
}
.btn-1 button.btn.btn-primary {
  background: #ffffff;
  color: #1f1e23;
}
.btn-1 button.btn.btn-primary:hover {
  border: 1px solid #ffffff;
}
.btn-1 {
  padding-right: 30px;
}
.troo_da_hero_wrapper .row {
  align-items: center !important;
}
.troo_da_hero_right_img {
  position: absolute;
  top: 80px;
}
.troo_da_hero_right_img img {
  width: 840px;
  height: 100%;
  object-fit: contain;
}

/* Global Banner */
.About_hero_wrapper {
  background: #1f1e23;
}
.about_content h2 {
  color: #fff;
  text-align: center;
}
.About_hero_wrapper {
  background: #1f1e23 url("../../images/about_hero_bg.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  padding: 120px 0;
  position: relative;
}
.troo_da_why_people_choose_wrapper.about_us_why_choose {
  background: url("../../images/about_us_people_choose.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.our_awesome_wrapper.about_us_our_awesome {
  padding-top: 117px;
  padding-bottom: 75px;
}

.breadcrumb-ss {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -28px;
  text-align: center;
  margin: 0 auto;
}
.breadcrumb {
  justify-content: center;
  align-items: center;
  list-style: none;
  background: #ffffff !important;
  border-bottom: 1px solid #fc7523;
  box-shadow: 0px 8px 24px rgb(149 157 165 / 10%) !important;
  border-radius: 5px !important;
  margin: 0 auto;
  display: inline-block !important;
  padding: 15px 28px !important;
  margin-bottom: 0 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0 !important;
  content: "" !important;
  width: 5px;
  height: 5px;
  background: #fc7523;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 9px;
}
li.breadcrumb-item {
  display: inline-block !important;
}
.breadcrumb-item a:not(:hover) {
  text-decoration: none;
}
ol.breadcrumb a {
  color: #1f1e23;
  font-size: 16px;
  line-height: 1.228;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
li.breadcrumb-item.current a {
  color: #fc7523;
}
ul.breadcrumb li a:hover {
  text-decoration: none;
}
.breadcrumb a {
  text-transform: capitalize;
  text-decoration: none;
  color: inherit;
}
.item-uppercase a {
  text-transform: uppercase !important;
}

@media (max-width: 1300px) {
  .troo_da_hero_right_img {
    top: 22%;
  }
  .troo_da_hero_right_img img {
    width: 600px;
   
}
}
@media (max-width: 1150px) {
  .troo_da_hero_wrapper {
    padding-top: 160px;
    padding-bottom: 80px;
}
}
@media (max-width: 991px) {
  .troo_da_hero_right_img {
    position: inherit;
    text-align: center;
}

  .hero_small_detail::after {
    width: 35%;
    height: 35%;
    background-size: contain;
    top: 10px;
    right: -45px;
}
  .hero_title {
    padding-top: 15px;
   
  }
  .troo_da_hero_left_detail {
    padding-top: 80px;
  }
  .troo_da_hero_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero_content {
    padding-top: 15px;
    
  }
  .hero_btn_outer {
    padding-top: 20px;
   
  }

  .our_awesome_wrapper.about_us_our_awesome {
    padding-top: 45px;
    padding-bottom: 30px;
  }
  .troo_da_hero_right_img img {
    width: 500px;
   
}
}

@media (max-width:767px) {
  .About_hero_wrapper {
    padding: 80px 0;
  }
}
@media (max-width: 540px) {
  .hero_small_detail{
    max-width: 100%;
  }
  .hero_small_detail::after{
    display: none;
  }
}