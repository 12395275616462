.troo_da_client_say_Wrapper {
  background: #fdf0e6 url("../../images/client_wrapper_bg_wave.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 95px;
  padding-bottom: 100px;
}
.client_say_box {
  background: #ffffff;
  box-shadow: 0px 7px 29px rgb(100 100 111 / 20%);
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 38px 16px 54px 17px;
  text-align: center;
  position: relative;
}
.client_say_box_title h4 {
  font-weight: 700;
  font-family: "Playfair Display", serif;
  transition: all 0.3s ease;
  margin: 0;
}
.client_say_box:hover .client_say_box_title h4 {
  color: #fc7523;
}
.client_say_box_title {
  padding-bottom: 21px;
}
.client_say_box_content {
  width: 100%;
  padding-bottom: 27px;
}
.client_say_box:hover .client_box_client_name h5 {
  color: #fc7523;
}
.client_box_star .fa {
  color: #fc7523;
}
.client_box_star {
  padding-bottom: 8px;
}
.client_box_client_name p {
  margin-top: 9px;
}
.client_box_person_img {
  /* margin-bottom: -90px;  */
  position: absolute;
  bottom: -30px;
  right: 0;
  left: 0;
  padding-top: 21px;
}
.client_say_section_wrapper {
  padding-bottom: 41px;
}
.client_say_box:hover:after {
  content: "";
  background: url("../../images/client_say_hover_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 209px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.client_say_btn {
  padding-top: 88px;
}
.client_say_btn button.btn.btn-primary {
  padding: 19px 22px 20px 22px;
}

/* clienmt page */
.troo_da_client_say_Wrapper.our_testimonial_client_say {
  background: transparent;
}
.row.client_reviews {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}
.brand_logo_wrapper.our_testimonial_brand_logo {
  padding-top: 55px;
}

@media (max-width: 991px) {
  .troo_da_client_say_Wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .client_say_section_wrapper {
    padding-bottom: 25px;
  }

  .troo_da_client_say_Wrapper .col-lg-4 {
    margin-bottom: 55px;
  }
  .client_say_btn {
    padding-top: 20px;
  }
  .brand_logo_wrapper.our_testimonial_brand_logo {
    padding-top: 25px;
  }
  .row.client_reviews {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
}
