.troo_da_teste_feel_wrapper {
  background: url("../../images/book_table_bg_img.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 101px 0;
}
.book_table_overlay {
  width: 100%;
  height: 100%;
  background: #1f1e23;
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.troo_da_teste_feel_wrapper > .container {
  position: relative;
  z-index: 2;
}
.test_feel_title_outer .hero_small_detail.we_service_small_title {
  max-width: 199px;
  width: 100%;
}
.test_feel_title_outer .hero_small_detail.we_service_small_title p {
  color: #fff;
}
.test_feel_title_outer h2 {
  color: #fff;
}
.test_feel_title_outer span {
  color: #fc7523;
}

@media (max-width: 991px) {
  .test_feel_title_outer .hero_small_detail.we_service_small_title {
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .troo_da_teste_feel_wrapper {
    padding: 50px 0;
  }
}
